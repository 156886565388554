import React from 'react'
import './experience.css'
import {HiCheckBadge} from 'react-icons/hi2'
const Experience = () => {
  return (
    <section id = 'experience'>
  <h5>Background</h5>
  <h2>Skill Set Includes</h2>

    <div className='container experience_container'>
      <div className='experience_frontend'>
        <h3>Frontend Development</h3>
        <div className='experience_content'>
          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>HTML</h4>
            <small className='text-light'>Expert</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>CSS</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>React</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>Javascript</h4>
            <small className='text-light'>Expert</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>SwiftUI</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>Angular</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

         
        </div>
      </div>

      <div className='experience_backend'>
      <h3>Backend Development</h3>
        <div className='experience_content'>
          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>C++</h4>
            <small className='text-light'>Expert</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>Java</h4>
            <small className='text-light'>Expert</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>Python</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>C#</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>SQL</h4>
            <small className='text-light'>Expert</small>
            </div>
          </article>


          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>Node JS</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

          <article className='experience_details'>
            <HiCheckBadge className='experience_details-icon'/>
            <div>
            <h4>PHP</h4>
            <small className='text-light'>Intermediate</small>
            </div>
          </article>

         
        </div>
      </div>

      
    </div>
    </section>
  )
}

export default Experience